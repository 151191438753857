import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './AddCee.module.scss'
import useFields, { FIELDS } from 'common-hooks/useFields'
import { Button, Input, RadioGroup, SimpleSelect } from 'components'
import useI18n from 'common-hooks/useI18n'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useId } from 'react'
import { anteriorSegment, keratometry, ophthalmoscopy, previousCurrent, pupilReflex, subjectiveRefraction, unaidedVision } from './constant'
import AnteriorSegementImage from './anteriorSegment.png'
import OphthalmoscopyImage from './ophthalmoscopy.png'
import PrescriptionFields from 'components/prescription/Prescription'
import { useNavigate } from 'react-router'
import { useReactToPrint } from 'react-to-print'
import EyewaLogo from './../../../../icons/eyewa-logo.png'
import UAEStamp from './../../../../icons/uae-stamp.png'
import KSAStamp from './../../../../icons/ksa-stamp.png'
import { useSelector } from 'react-redux'
import { selectDefaults } from 'appSlice'

const AddCeeFields = forwardRef(({ onSubmit, loading }, ref) => {
  const { i18n } = useI18n();
  const id = useId();
  const { setValue } = useFormContext();
  const { renderField } = useFields();
  const navigate = useNavigate();
  const defaultValues = useSelector(selectDefaults);

  const formFields = useWatch();

  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  // Expose handlePrint to parent component
  useImperativeHandle(ref, () => ({
    handlePrint,
  }));

  return (
    <form onSubmit={onSubmit} className={cx(styles.form)}>
      <div ref={contentRef}>
        {/* eyewa logo */}
        <div className={styles.logoWrapper}>
          <img alt='logo' src={EyewaLogo} className={styles.logo} />
        </div>
        <div className={cx(styles['name-upload'])}>
          {renderField(FIELDS.FULL_NAME, cx(styles.field))}
          {renderField(FIELDS.PHONE, cx(styles.field))}
        </div>

        {/* Previous/Current Section */}
        <div>
          <label className={cx(styles.label)} htmlFor={id}>{i18n('Previous/Current :')}</label>
          <div className={cx(styles.inputWrapper)}>
            {previousCurrent.map((item) => (
              <Controller
                name={item?.key}
                key={item?.key}
                render={({ field }) => (
                  <Input {...field} className={styles.inp} label={i18n(`${item?.label} :`)} />
                )}
              />
            ))
            }
          </div>
        </div>

        {/* Unaided Vision Section */}
        <div>
          <label className={cx(styles.label)} htmlFor={id}>{i18n('Unaided Vision (VA): ')}:</label>
          <div className={cx(styles.inputWrapper)}>
            {unaidedVision.map((item) => (
              <Controller
                name={item?.key}
                key={item?.key}
                render={({ field }) => (
                  <Input {...field} className={styles.inp} label={i18n(`${item?.label} :`)} />
                )}
              />
            ))
            }
          </div>
        </div>

        {/* Subjective Refraction Section */}
        <div>
          <label className={cx(styles.label)} htmlFor={id}>{i18n('Subjective Refraction :')}</label>
          <div className={cx(styles.inputWrapper)}>
            {
              subjectiveRefraction.map((item) => (
                <Controller
                  name={item?.key}
                  key={item?.key}
                  render={({ field }) => (
                    <Input {...field} className={styles.inp} label={i18n(`${item?.label} :`)} />
                  )}
                />
              ))
            }
          </div>
        </div>

        {/* Keratometry Section */}
        <div>
          <label className={cx(styles.label)} htmlFor={id}>Keratometry:</label>
          <div className={cx(styles.keratometryWrapper)}>
            {keratometry.map(section => (
              <div key={section.side} className={cx(styles.keratometrySection)}>
                <div className={cx(styles.inputWrapper)}>
                  {section.fields.map(item => (
                    <Controller
                      name={item?.key}
                      key={item?.key}
                      render={({ field }) => (
                        <Input {...field} className={styles.inp} label={i18n(`${section.label} ${item?.label} :`)} />
                      )}
                    />
                  ))}
                </div>
                <div>
                  <label className={cx(styles.label)}>{i18n('Mires:')}</label>
                  <div className={cx(styles.miresSection)}>
                    <Controller
                      key={`mires-${section.side}`}
                      name={`mires-${section.side}`}
                      render={({ field }) => {
                        return (
                          <RadioGroup
                            {...field}
                            options={[
                              { label: i18n(`Clear`), value: `Clear` },
                              { label: i18n(`Not clear`), value: `Not clear` },
                              { label: i18n(`Distortion`), value: `Distortion` },
                            ]}
                            onChange={(e) => {
                              field.onChange(e);
                              setValue(`distortion-${section.side}`, '');
                            }}
                          />
                        )
                      }}
                    />

                    {formFields[`mires-${section.side}`] === 'Distortion' && (
                      <Controller
                        name={`distortion-${section.side}`}
                        key={`distortion-${section.side}`}
                        render={({ field }) => (
                          <SimpleSelect
                            {...field}
                            options={[1, 2, 3, 4].map(level => ({ label: `+${level}`, value: level }))}
                            placeholder={i18n('Select level')}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Binocular Examination Section */}
        <div className={cx(styles.binocularSection)}>
          <label className={cx(styles.label)}>{i18n('Binocular Examination :')}</label>
          <div className={cx(styles.binocularWrapper)}>
            <div className={cx(styles.binocularLeftSection)}>
              <label className={cx(styles.label)}>{i18n('Cover test :')}</label>
              <div className={cx(styles.inputGroup)}>
                <Controller
                  name={'binocular-cover-test-distance-without-rx'}
                  key={'binocular-cover-test-distance-without-rx'}
                  render={({ field }) => (
                    <Input {...field} className={styles.inp} label={i18n('Distance CT without Rx:')} />
                  )}
                />
                <Controller
                  name={'binocular-cover-test-distance-with-rx'}
                  key={'binocular-cover-test-distance-with-rx'}
                  render={({ field }) => (
                    <Input {...field} className={styles.inp} label={i18n('Distance CT with Rx:')} />
                  )}
                />
              </div>
              <div className={cx(styles.inputGroup)}>
                <Controller
                  name={'binocular-cover-test-near-without-rx'}
                  key={'binocular-cover-test-near-without-rx'}
                  render={({ field }) => (
                    <Input {...field} className={styles.inp} label={i18n('Near CT without Rx:')} />
                  )}
                />
                <Controller
                  name={'binocular-cover-test-near-with-rx'}
                  key={'binocular-cover-test-near-with-rx'}
                  render={({ field }) => (
                    <Input {...field} className={styles.inp} label={i18n('Near CT with Rx:')} />
                  )}
                />
              </div>
            </div>

            <div className={cx(styles.binocularRightSection)}>
              <div className={cx(styles.inputGroup)}>
                <Controller
                  name={'binocular-amplitude-of-accommodation'}
                  key={'binocular-amplitude-of-accommodation'}
                  render={({ field }) => (
                    <Input {...field} className={styles.inp} label={i18n('Amplitude of accommodation:')} />
                  )}
                />
                <Controller
                  name={'binocular-near-point-of-convergence'}
                  key={'binocular-near-point-of-convergence'}
                  render={({ field }) => (
                    <Input {...field} className={styles.inp} label={i18n('Near point of convergence:')} />
                  )}
                />
              </div>
              <div className={cx(styles.inputGroup)}>
                <Controller
                  name={'binocular-maddox-rod'}
                  key={'binocular-maddox-rod'}
                  render={({ field }) => (
                    <Input {...field} className={styles.inp} label={i18n('Maddox rod:')} />
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Pupil Reflex Section */}
        <div>
          <label className={cx(styles.label)}>{i18n('Pupil Reflex :')}</label>
          <div className={cx(styles.pupilWrapper)}>
            {pupilReflex.map((section) => (
              <div className={cx(styles.pupilSection)}>
                <label className={cx(styles.label)}>{i18n(`${section?.key} :`)}</label>
                <div className={cx(styles.pupilSectionInputGroup)}>
                  {section.fields.map(item => (
                    <Controller
                      name={item?.key}
                      key={item?.key}
                      render={({ field }) => (
                        <Input {...field} className={styles.inp} label={i18n(`${item?.label} :`)} />
                      )}
                    />
                  ))
                  }
                </div>
              </div>
            ))
            }
          </div>
        </div>

        {/* Anterior segment examination */}

        <div>
          <label className={cx(styles.label)}>{i18n('Anterior segment examination :')}</label>
          <div className={cx(styles.anteriorSegmentWrapper)}>
            <div className={cx(styles.anteriorSegmentSection)}>
              <Controller
                name={'anterior-segment-od'}
                key={'anterior-segment-od'}
                render={({ field }) => (
                  <Input {...field} className={styles.inp} label={i18n('OD :')} />
                )}
              />

              <img src={AnteriorSegementImage} alt="Anterior Segment" />
            </div>
            <div className={cx(styles.anteriorSegmentSection)}>
              {anteriorSegment.map((item) => (
                <Controller
                  name={item?.key}
                  key={item?.key}
                  render={({ field }) => (
                    <Input {...field} className={styles.inp} label={i18n(`${item?.label} :`)} />
                  )}
                />
              ))
              }

            </div>
            <div className={cx(styles.anteriorSegmentSection)}>
              <Controller
                name={'anterior-segment-os'}
                key={'anterior-segment-os'}
                render={({ field }) => (
                  <Input {...field} className={styles.inp} label={i18n('OS :')} />
                )}
              />
              <img src={AnteriorSegementImage} alt="Anterior Segment" />
            </div>
          </div>
        </div>

        {/* Ophthalmoscopy */}

        <div>
          <label className={cx(styles.label)}>{i18n('Ophthalmoscopy :')}</label>
          <div className={cx(styles.ophthalmoscopyWrapper)}>
            <div className={cx(styles.ophthalmoscopySection)}>
              <Controller
                name={'ophthalmoscopy-od'}
                key={'ophthalmoscopy-od'}
                render={({ field }) => (
                  <Input {...field} className={styles.inp} label={i18n('OD :')} />
                )}
              />
              <img src={OphthalmoscopyImage} alt="Ophthalmoscopy" />
            </div>
            <div className={cx(styles.ophthalmoscopySection)}>
              {ophthalmoscopy.map((item) => (
                <Controller
                  name={item?.key}
                  key={item?.key}
                  render={({ field }) => (
                    <Input {...field} className={styles.inp} label={i18n(`${item?.label} :`)} />
                  )}
                />
              ))
              }
            </div>
            <div className={cx(styles.ophthalmoscopySection)}>
              <Controller
                name={'ophthalmoscopy-os'}
                key={'ophthalmoscopy-os'}
                render={({ field }) => (
                  <Input {...field} className={styles.inp} label={i18n('OS :')} />
                )}
              />
              <img src={OphthalmoscopyImage} alt="Ophthalmoscopy" />
            </div>
          </div>
        </div>

        {/* dry test */}
        <div>
          <label className={cx(styles.label)}>{i18n('Dry test :')}</label>
          <div className={cx(styles.dryTestWrapper)}>
            <Controller
              name={'dry-test-od'}
              key={'dry-test-od'}
              render={({ field }) => (
                <Input {...field} className={styles.inp} label={i18n('OD :')} />
              )}
            />
            <Controller
              name={'dry-test-os'}
              key={'dry-test-os'}
              render={({ field }) => (
                <Input {...field} className={styles.inp} label={i18n('OS :')} />
              )}
            />
          </div>
        </div>

        <PrescriptionFields noValidate />

        <div className={styles.extraMargin}></div>

        <div>
          <Controller
            name={'impression'}
            key={'impression'}
            render={({ field }) => (
              <Input {...field} className={cx(styles.inp)} label={i18n('Impression:')} />
            )}
          />
          <Controller
            name={'management'}
            key={'management'}
            render={({ field }) => (
              <Input {...field} className={cx(styles.inp)} label={i18n('Management:')} />
            )}
          />
          <Controller
            name={'plan'}
            key={'plan'}
            render={({ field }) => (
              <Input {...field} className={styles.inp} label={i18n('Plan:')} />
            )}
          />
          <Controller
            name={'follow-up-appointment'}
            key={'follow-up-appointment'}
            render={({ field }) => (
              <Input {...field} className={styles.inp} label={i18n('Follow-up appointment:')} />
            )}
          />
          <Controller
            name={'optometrist'}
            key={'optometrist'}
            render={({ field }) => (
              <Input {...field} className={styles.inp} label={i18n('Optometrist:')} />
            )}
          />
        </div>
        <div className={styles.eStampWrapper}>
          <img alt='e-stamp' src={defaultValues.country === 'ae' ? UAEStamp : KSAStamp} className={styles.stamp} />
          <div className={styles.signature}>
            Optometrist Signature
          </div>
        </div>
      </div>

      <div className={styles.buttonWrapper}>
        <Button loading={loading} type="submit" className={cx(styles.submit)}>{i18n('Save')}</Button>
        <Button loading={loading} type="button" className={cx(styles.submit)} theme='secondary' onClick={() => navigate(-1)}>{i18n('Cancel')}</Button>
      </div>
    </form>
  )
})

AddCeeFields.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
}

export default AddCeeFields
