import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { FormProvider, useForm } from 'react-hook-form'
import { useAddComprehensivePrescriptionMutation, useLazyGetComprehensivePrescriptionByIdQuery } from 'app/service'
import { useLocation, useNavigate, useParams } from 'react-router'
import useI18n from 'common-hooks/useI18n'
import { toast } from 'react-toastify'
import AddCeeFields from './AddCeeFields'
import styles from './AddCee.module.scss'
import cx from 'classnames'
import { Button } from 'components'


function AddCee({ customer }) {
  const { i18n } = useI18n();
  const { id } = useParams();
  const addCeeFieldsRef = useRef();
  const methods = useForm({ mode: 'all', defaultValues: { id, phone: customer.mobileNumber, fullName: customer.firstName + ' ' + customer.lastName } });
  const [addComprehensivePrescription, { isLoading: isSubmitting }] = useAddComprehensivePrescriptionMutation();
  const [getComprehensivePrescription, { data: comprehensivePrescription, isLoading: loadingComprehensivePrescription, isUninitialized }] = useLazyGetComprehensivePrescriptionByIdQuery();
  const navigate = useNavigate();
  const { state } = useLocation();

  const handlePrintClick = () => {
    if (addCeeFieldsRef.current) {
      addCeeFieldsRef.current.handlePrint();
    }
  };

  const handlePrintClickTimeout = () => {
    setTimeout(() => {
      handlePrintClick();
    }, 1000);
  }


  const handleSubmit = async formData => {
    try {
      const { data: response } = await addComprehensivePrescription({ data: { id, customerId: customer.id, appointmentId: formData.appointmentId, data: { ...formData } } });
      if (response) {
        navigate(`/customers/${customer.id}/prescriptions`);
      }
    } catch (error) {
      toast.error(i18n('Failed to add prescription'));
    } finally {

    }
  }

  useEffect(() => {
    if (id && isUninitialized) {
      getComprehensivePrescription(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (comprehensivePrescription) {
      methods.reset(comprehensivePrescription?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comprehensivePrescription])

  useEffect(() => {
    if (state?.appointmentId) {
      methods.setValue('appointmentId', state.appointmentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.appointmentId])

  useEffect(() => {
    if (customer) {
      methods.setValue('phone', customer.mobileNumber);
      methods.setValue('fullName', customer.firstName + ' ' + customer.lastName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    if (state?.print) {
      handlePrintClickTimeout();
    }
  }, [state?.print])

  return (
    <div className={cx(styles.wrapper)}>
      <div className={cx(styles.title)}>
        <h2>Comprehensive prescription</h2>
        {id && <Button size='extra-small' type='button' onClick={handlePrintClick}>Print</Button>}
      </div>
      <FormProvider {...methods}>
        <AddCeeFields ref={addCeeFieldsRef} loading={isSubmitting || loadingComprehensivePrescription} onSubmit={methods.handleSubmit(handleSubmit)} />
      </FormProvider>
    </div>
  )
}

AddCee.propTypes = {
  customer: PropTypes.object
}

export default AddCee
