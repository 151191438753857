import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import cx from 'classnames'
import styles from './FormElements.module.scss';
import { useId } from 'react';
import Skeleton from 'components/skeleton/Skeleton';

const ARROW = <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M0.52827 0.52827C0.788619 0.26792 1.21073 0.26792 1.47108 0.52827L4.99967 4.05687L8.52827 0.52827C8.78862 0.26792 9.21073 0.26792 9.47108 0.52827C9.73143 0.788619 9.73143 1.21073 9.47108 1.47108L5.47108 5.47108C5.21073 5.73143 4.78862 5.73143 4.52827 5.47108L0.52827 1.47108C0.26792 1.21073 0.26792 0.788619 0.52827 0.52827Z" fill="#31395F"/> </svg>

function SimpleSelect({ onChange, error, className, value, placeholder, loading, options, label, onBlur, disabled, isSearchable = false, ...rest }) {
  const id = useId();
  const handleChange = e => {
    if (onBlur) onBlur(e.value);
    onChange(e.value);
  }

  return (
    <div className={cx(styles.field, className, { [styles.error]: !!error, [styles.disabled]: disabled } )}>
      {label && <label className={cx(styles.label)} htmlFor={id}>{label}</label>}
      {loading ? <Skeleton type="input" /> : (
        <Select
          isSearchable={isSearchable}
          disabled={disabled}
          unstyled
          id={id}
          placeholder={placeholder}
          components={{
            DropdownIndicator: () => ARROW
          }}
          classNames={{
            control: state => cx(styles['select-control'], styles.input, { [styles['select-control-has-value']]: (state.hasValue && state.getValue()[0].value !== '') }),
            menu: () => cx(styles['select-menu']),
            option: state => cx(styles['select-option'], { [styles.selected]: state.isSelected && state.getValue()[0].value !== '' }),
          }}
          options={options}
          value={options.find(x => x.value === value)}
          onChange={handleChange}
          {...rest}
        />
      )}
      {error && <label htmlFor={id} className={cx(styles.message)}>{error}</label>}
    </div>
  )
}

SimpleSelect.propTypes = {
  onChange: PropTypes.func,
  error: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  i18n: PropTypes.func,
  loading: PropTypes.bool,
  options: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string
}

export default SimpleSelect
