import React from 'react';
import { useFlexLayout, useTable } from 'react-table'
import cx from 'classnames'
import styles from './Table.module.scss';
import noResultsIcon from 'components/message/error.png'
import PropTypes from 'prop-types'
import Skeleton from 'components/skeleton/Skeleton';

const Table = ({ columns, items, isLoading }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: items }, useFlexLayout);

  return (
    <>
      {isLoading && (
        <div className={cx(styles['table-skeleton'])}>
          <Skeleton type="table" />
        </div>
      )}
      {!isLoading && items.length <= 0 && (
        <div className={cx(styles['no-results'])}>
          <img className={cx(styles['no-results-icon'])} src={noResultsIcon} alt="" />
          <p className={cx(styles['no-results-message'])}>{'No results'}</p>
          <p className={cx(styles['no-results-info'])}>{'Please try refining your search'}</p>
        </div>
      )}
      {!isLoading && items.length > 0 && (
        <div className={cx(styles['table-wrapper'])} id="tableContainer">
          <div {...getTableProps()} className={cx(styles.table, styles.table)}>
            <div>
              {headerGroups.map(headerGroup => (
                <div {...headerGroup.getHeaderGroupProps()} className={cx(styles['table-header'])}>
                  {headerGroup.headers.map(column => {
                    return <div {...column.getHeaderProps([{ className: cx(styles[column.className], styles.th, styles.th) }])}>{column.render('Header')}</div>
                  })}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className={cx(styles.row)}>
                    {row.cells.map((cell) => {
                      return <div {...cell.getCellProps([{ className: cx(styles.cell, styles[cell.column.className]) }])}>
                        {cell.render('Cell')}
                      </div>;
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool
}

export default Table