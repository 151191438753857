import Register from 'pages/register/Register'
import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import styles from './appv2.scss'
import cx from 'classnames'
import Home from 'pages/home/Home'
import { useLazyExternalDoctorsQuery } from 'app/service'
import { addExternalDoctors } from 'appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectToken, selectUser } from 'features/login/loginSlice'
import Add from 'pages/customers/add/Add'
import Info from 'pages/customers/edit/info/Info'
import Prescriptions from 'pages/customers/prescriptions/Prescriptions'
import Order from 'pages/orders/order/Order'
import Dashboard from 'pages/dashboard/Dashboard'
import OrdersCards from 'pages/orders/OrderCards'
import Orders from 'pages/orders/Orders'
import Admin from 'pages/admin/Admin'
import List from 'pages/availability/list/List'
import Availability from 'pages/availability/Availability'
import Calendar from 'pages/availability/Calendar/Calendar'
import Booking from 'pages/booking/Booking'
import DefaultStore from 'pages/default-store/DefaultStore'
import Login from 'pages/login/Login'
import Appointments from 'pages/appointments/Appointments'
import Warehouses from 'pages/warehouses/Warehouses'
import Warehouse from 'pages/warehouses/warehouse/Warehouse'
import PendingPrescriptions from 'pages/orders/PendingPrescriptions'
import DeliveryPromiseCalculator from 'pages/delivery-promise-calculator/DeliveryPromiseCalculator'
import OrderDetails, { SUB_PAGES } from 'pages/orders/order-details/OrderDetails'
import AdminConfig from 'pages/settings/AdminConfig'
import Settings from 'pages/settings/Settings'
import Users from 'pages/users/Users'
import AddUser from 'pages/users/add/Add'
import EditUser from 'pages/users/edit/Edit'
import ResetPassword from 'pages/reset-password/ResetPassword'
import { ROLE_TYPES } from 'pages/users/Roles'
import SwitchStore from 'pages/switch-store/SwitchStore'
import EyeTestsPerformanceDashboard from 'pages/eye-tests/EyeTestsPerformanceDashboard'
import Step1 from 'pages/customers/add/steps/Step1'
import Step2 from 'pages/customers/add/steps/Step2'
import Step3 from 'pages/customers/add/steps/Step3'
import Questionnaire from 'pages/customers/add/Questionnaire'
import Step4 from 'pages/customers/add/steps/Step4'
import Step5 from 'pages/customers/add/steps/Step5'
import Step6 from 'pages/customers/add/steps/Step6'
import ThankYou from 'pages/customers/add/steps/ThankYou'
import DeliveryManagement from 'pages/delivery-management/DeliveryManagement'
import Drivers from 'pages/delivery-management/drivers/Drivers'

import TransferOrders from 'pages/orders/TransferOrders'
import TransferOrdersStaff from 'pages/orders/TransferOrdersStaff'

import BookingAvailabilityDashboard from 'pages/store-analytics/booking-availability/BookingAvailabilityDashboard'
import StoreAnalytics from 'pages/store-analytics/StoreAnalytics'

import Review from 'pages/review/Review'
import StoreInfo from 'pages/store-analytics/store-info/StoreInfo'
import StoreAppointments from 'pages/store-analytics/store-appointments/StoreAppointments'


export default function AppV2() {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [getExternalDoctors] = useLazyExternalDoctorsQuery();
  const { roleList } = useSelector(selectUser);

  const fetchExternalDoctors = async () => {
    const { data } = await getExternalDoctors();
    dispatch(addExternalDoctors(data));
  };

  useEffect(() => {
    if (token) {
      fetchExternalDoctors()
    };
  }, [token]);

  const ProtectedRoute = ({ type, redirectPath = '/', children, }) => {
    const loggedInUserRole = roleList?.[0]?.roleName
    if ([ROLE_TYPES.ADMIN, ROLE_TYPES.EG_ADMIN]?.includes(loggedInUserRole)) {
      return children
    }
    if (!type?.includes(loggedInUserRole)) {
      return <Navigate to={redirectPath} />
    }
    return children
  }

  return (
    <div className={cx(styles.wrapper)}>
      <Routes>
        <Route path='/register/*' element={<Register />} />
        <Route path='/customers/add' element={<Add />} />
        <Route path='/customers/:customerId/historyInfo' element={<Questionnaire />} >
          <Route path="1" element={<Step1 />} />
          <Route path="2" element={<Step2 />} />
          <Route path="3" element={<Step3 />} />
          <Route path="4" element={<Step4 />} />
          <Route path="5" element={<Step5 />} />
          <Route path="6" element={<Step6 />} />
          <Route path="thankyou" element={<ThankYou />} />
        </Route>
        <Route path='/customers/:email/edit/info' element={<Info />} />
        <Route path='/customers/:email/prescriptions/*' element={<Prescriptions />} />
        <Route path='/pending-prescriptions' element={<PendingPrescriptions />} />
        <Route path='/orders' element={<OrdersCards />} />
        <Route path='/order-grid' element={<Orders />} />
        <Route path='/transfer-orders-staff' element={<TransferOrdersStaff />} />
        <Route path='/transfer-orders-cs' element={<TransferOrders />} />
        <Route path='/orders/:orderid' element={<Order />} />
        <Route path='/orders/:orderid/sku/:productid' element={<Order />} />
        <Route path='/order-details/:orderid' element={<OrderDetails />}>
          {SUB_PAGES.map(x => (
            <Route key={x.path} path={`${x.path}`} element={<x.element />} />
          ))}
        </Route>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/config' element={<Admin />} />
        <Route path='/settings' element={<Settings />} >
          <Route path='warehouses' element={<Warehouses />} />
          <Route path='warehouses/:warehouse' element={<ProtectedRoute redirectPath='/settings/warehouses' type={[ROLE_TYPES.ADMIN, ROLE_TYPES.EG_ADMIN]}><Warehouse /></ProtectedRoute>} />
          <Route path='warehouses/new' element={<ProtectedRoute redirectPath='/settings/warehouses' type={[ROLE_TYPES.ADMIN, ROLE_TYPES.EG_ADMIN]}><Warehouse /></ProtectedRoute>} />
          <Route path='users' element={<Users />} />
          <Route path='users/add' element={<AddUser />} />
          <Route path='users/:id' element={<EditUser />} />
          <Route path='setup' element={<AdminConfig />} />

        </Route>
        <Route path='/availability' element={<Availability />} />
        <Route path='/availability/:store/list' element={<List />} />
        <Route path='/availability/:store/calendar' element={<Calendar />} />
        <Route path='/booking/:userId' element={<Booking />} />
        <Route path='/default-store' element={<DefaultStore />} />
        <Route path='/appointments' element={<Appointments />} />
        <Route path='/login' element={<Login />} />
        <Route path='/delivery-promise' element={<DeliveryPromiseCalculator />} />
        <Route path='update-password' element={<ResetPassword />} />
        <Route path='switch-store' element={<SwitchStore />} />
        <Route path='performance-details' element={<EyeTestsPerformanceDashboard />} />
        <Route path='delivery-management' element={<DeliveryManagement />}>
          <Route path="drivers" element={<Drivers />} />
          <Route path="driver-runsheet" element={<Users />} />
        </Route>

        <Route path='/store-analytics' element={<StoreAnalytics />}>
          <Route path="eyetest-schedule" element={<BookingAvailabilityDashboard />} />
          <Route path="store-info" element={<StoreInfo />} />
          <Route path="store-appointments" element={<StoreAppointments />} />
        </Route>
        <Route path='/' element={<Home />} />
        <Route path='/review' element={<Review/>}/>
      </Routes>
    </div>
  )
}
