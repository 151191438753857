import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Typography from '@eyewa/core-typography/dist/Typography'
import styles from './Section.module.scss'

function Section({ title, children, className }) {
  return (
    <div className={cx(styles.wrapper, className)}>
      <Typography className={cx(styles.title)} size="lg" tag="h2" weight="bold">{title}</Typography>
      <div className={cx(styles.content)}>{children}</div>
    </div>
  )
}

Section.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string
}

export default Section
