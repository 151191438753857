import React from 'react'
import { SectionNav } from 'components'
import useI18n from 'common-hooks/useI18n';

function StoreAnalyticsNav({ active }) {
  const { i18n } = useI18n();

  return (
    <SectionNav active={active}
      items={[
        { label: i18n('Eyetest Booking Availability List'), to: 'eyetest-schedule' },
        { label: i18n('Store info'), to: 'store-info' },
        { label: i18n('Store appointments'), to: 'store-appointments' },
      ]}
    />
  )
}

StoreAnalyticsNav.propTypes = {}

export default StoreAnalyticsNav
